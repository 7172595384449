export const saveVideosHistory = (videos) =>
  new Promise((resolve, reject) => {
    let API_HOST = 'https://woxo.tech';

    if (typeof window !== 'undefined') {
      API_HOST = window.location.origin;
    }
    fetch(`${API_HOST}/api/i2v/history`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(videos)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // console.log('Videos published successfully', data);
        resolve(data);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
  });

export const getVideosHistory = (userId, page = 1) =>
  new Promise((resolve, reject) => {
    let API_HOST = 'https://woxo.tech';

    if (typeof window !== 'undefined') {
      API_HOST = window.location.origin;
    }
    fetch(`${API_HOST}/api/i2v/history?userId=${userId}&page=${page}&limit=8`, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log('Videos_', data);
        resolve(data);
      })
      .catch((error) => {
        console.error('Error:', error);
        reject(error);
      });
  });

export const updateVideosHistory = (userId, sectionId, data) =>
  new Promise((resolve, reject) => {
    let API_HOST = 'https://woxo.tech';

    if (typeof window !== 'undefined') {
      API_HOST = window.location.origin;
    }
    fetch(`${API_HOST}/api/i2v/history?userId=${userId}&sectionId=${sectionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        resolve(data);
      })
      .catch((error) => {
        console.log('Error:', error);
        reject(error);
      });
  });
