import { getVideosBySections, updateProportionInVideos } from '../helpers/helper';
import {
  saveVideosHistory,
  getVideosHistory,
  updateVideosHistory
} from '../helpers/history.services';

export const useHistoryStore = (set, get) => ({
  // state
  isLoadingHistorical: false,
  historicalSections: [],
  videosByHistoricalSections: [],
  historyPagination: { currentPage: 1, totalPages: 1 },

  // actions
  saveVideosHistoryAction: async (videos) => {
    try {
      await saveVideosHistory(videos);
    } catch (error) {
      console.error(error);
    }
  },

  getVideosHistoryAction: async (page) => {
    try {
      set({ isLoadingHistorical: true });
      const _videos = await getVideosHistory(get().user._id, page);
      // console.log('___', _videos);
      const _historyPagination = {
        currentPage: page,
        totalPages: _videos.totalPages
      };

      const _sections = updateProportionInVideos(get().videoProportion, _videos.videos);

      set(
        {
          historicalSections: _sections,
          videosByHistoricalSections: getVideosBySections(_sections),
          isLoadingHistorical: false,
          historyPagination: _historyPagination
        },
        false,
        'init'
      );
    } catch (error) {
      console.error(error);
    }
  },

  updateVideosHistoryAction: async (userId, sectionId, data) => {
    try {
      await updateVideosHistory(userId, sectionId, data);
    } catch (error) {
      console.error(error);
    }
  }
});
